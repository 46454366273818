import React from 'react'
import { Card, CircularProgress, IconButton, Link } from '@material-ui/core'
import authFetchHandler from './common/tools/auth/authFetchHandler'
import { Button, Typography, Divider, Breadcrumbs } from '@material-ui/core'
import { OutlinedTextField } from './common/fields'
import { useSnackbar } from 'notistack'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import HomeIcon from '@material-ui/icons/Home'
import DescriptionIcon from '@material-ui/icons/Description'
import ListIcon from '@material-ui/icons/List'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
const borderColor = 'rgba(0,0,0,0.3)'

// DB Interface
async function fetchUser(userId) {
  try {
    const resp = await authFetchHandler(`admin-query-user?userId=${userId}`)
    return await resp.json()
  } catch (err) {
    console.log(err)
    return
  }
}

async function fetchLicense(licenseId) {
  try {
    const resp = await authFetchHandler(`admin-query-license?licenseId=${licenseId}`)
    return await resp.json()
  } catch (err) {
    console.log(err)
    return
  }
}

async function fetchOrder(orderId) {
  try {
    const resp = await authFetchHandler(`admin-query-order?orderId=${orderId}`)
    const order = await resp.json()
    order.claimCode = order.number
    return order
  } catch (err) {
    console.log(err)
    return
  }
}

async function fetchGroup(groupId) {
  try {
    const resp = await authFetchHandler(`admin-query-group?groupId=${groupId}`)
    return await resp.json()
  } catch (err) {
    console.log(err)
    return
  }
}

async function fetchQuery({
  userId,
  userName,
  userEmail,
  authId,
  orderId,
  claimCode,
  purchaserEmail,
}) {
  try {
    const resp = await authFetchHandler(`admin-query?userId=${userId}&userName=${userName}&userEmail=${userEmail}&authId=${authId}&orderId=${orderId}&claimCode=${claimCode}&purchaserEmail=${purchaserEmail}`)
    return await resp.json()
  } catch (err) {
    console.log(err)
    return
  }
}

function fetchSource(source, id) {
  if (source === 'users') {
    return fetchUser(id)
  }
  if (source === 'licenses') {
    return fetchLicense(id)
  }
  if (source === 'orders') {
    return fetchOrder(id)
  }
  if (source === 'groups') {
    return fetchGroup(id)
  }
}

function makeDataStoreEntry({ data, lastUpdated = new Date(), promise }) {
  return {
    data,
    lastUpdated,
    promise,
  }
}

// DB Datastore
let db
resetDb()

function resetDb() {
  db = {
    users: {},
    licenses: {},
    orders: {},
    groups: {},
  }
}

async function getDbItem(source, id) {
  if (!db[source][id]) {
    const promise = fetchSource(source, id)
    db[source][id] = makeDataStoreEntry({ promise })
    const data = await promise
    if (data) {
      db[source][id] = makeDataStoreEntry({ data, promise: null })
    } else {
      throw new Error(`failed to get ${source}`)
    }
  }

  if (db[source][id].promise) {
    await db[source][id].promise
  }
  return db[source][id]
}
async function getUser(userId) {
  return await getDbItem('users', userId)
}

async function getLicense(licenseId) {
  return await getDbItem('licenses', licenseId)
}

async function getOrder(orderId) {
  return await getDbItem('orders', orderId)
}

async function getGroup(groupId) {
  return await getDbItem('groups', groupId)
}


// DBViewer Context
const PATH_TYPES = Object.freeze({
  LIST: 'list',
  DETAIL: 'detail',
})

class DBViewerController {
  setFunctions(funcs) {
    Object.keys(funcs).forEach(key => this[key] = funcs[key])
  }
}
const dbViewerControl = new DBViewerController()

const DBViewerStateContext = React.createContext()
function DBViewerProvider({ children }) {
  const queryStateDefaults = {
    userId: '',
    userName: '',
    userEmail: '',
    authId: '',
    orderId: '',
    claimCode: '',
    purchaserEmail: '',
  }
  const [queryState, setQueryState] = React.useState({...queryStateDefaults})
  const [queryResults, setQueryResults] = React.useState([])
  const [dbPath, setDbPath] = React.useState([])
  const snacks = useSnackbar()

  function handleQueryTextChange(key, value) {
    setQueryState({
      ...queryStateDefaults,
      [key]: value,
    })
  }

  function goToPathIndex(pathIdx) {
    setDbPath(x =>  x.slice(0, pathIdx + 1))
  }

  function handleHomeClick() {
    setDbPath((x) => {
      if (x.length === 0) {
        return []
      }
      return x.slice(0, 1)
    })
  }

  function goToListItemInPath(pathIdx, source, ids, key, listSubtitle) {
    setDbPath((x) => {
      return [...(x.slice(0, pathIdx+1)), {type: PATH_TYPES.LIST, source, ids, key, listSubtitle}]
    })
  }

  function goToDetailItemInPath(pathIdx, source, id, key) {
    setDbPath((x) => {
      return [...(x.slice(0, pathIdx+1)), {type: PATH_TYPES.DETAIL, source, id, key}]
    })
  }

  function addDetailItemToPath(source, id) {
    setDbPath(x => [...x, {type: PATH_TYPES.DETAIL, source, id}])
  }

  function listItemIsSelected(pathIdx, key) {
    try {
      return dbPath[pathIdx + 1].key === key
    } catch {
      //
    }
  }

  async function handleRunQuery(queryState) {
    resetDb()
    setDbPath([])
    const queryResult = await fetchQuery(queryState)
    if (queryResult?.orderIds?.length > 0) {
      setQueryResults(queryResult.orderIds)
      setDbPath([{type: PATH_TYPES.LIST, source: 'orders', ids: queryResult.orderIds}])
      return
    }

    if (queryResult?.userIds?.length > 0) {
      setQueryResults(queryResult.userIds)
      setDbPath([{type: PATH_TYPES.LIST, source: 'users', ids: queryResult.userIds}])
      return
    }

    snacks.enqueueSnackbar('Query returned 0 results.', {
      variant: 'error',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      autoHideDuration: 2000,
    })
  }

  dbViewerControl.setFunctions({
    handleQueryTextChange,
    handleRunQuery,
    addDetailItemToPath,
    goToDetailItemInPath,
    goToListItemInPath,
    handleHomeClick,
    goToPathIndex,
    listItemIsSelected,
  })

  return (
    <DBViewerStateContext.Provider
      value={{
        queryState,
        queryResults,
        dbPath,
      }}>
      {children}
    </DBViewerStateContext.Provider>
  )
}

function useDBViewer() {
  return React.useContext(DBViewerStateContext)
}

function DBViewerListButton({ value, onClick, selected, disabled }) {
  return (
    <div key={value}>
      <Button
        style={{
          textTransform: 'none',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderRadius: 0,
          backgroundColor: selected ? '#EEE7F5':null,
          color: selected ? '#681DA8' : null,
        }}
        onClick={onClick}
        disabled={disabled}
      >
        {value}
        <NavigateNextIcon />
      </Button>
      <Divider style={{backgroundColor: selected ? 'rgba(0,0,0,0.2)' : null}}/>
    </div>
  )
}

function ListView({ pathItem, selectedItem }) {
  return (
    <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%'}}>
      {pathItem.ids.map((id, idx) =>
        pathItem.source === 'licenses' ?
          <LicenseListItem {...{ pathItem, id }}/>:
          pathItem.source === 'groups' ?
            <GroupListItem {...{ pathItem, id }}/>:
            pathItem.source === 'users' ?
              <UserListView {...{ pathItem, id }}/>:
              pathItem.source === 'orders' ?
                <OrderListView {...{ pathItem, id }}/>:
                <DBViewerListButton
                  onClick={() => dbViewerControl.goToDetailItemInPath(pathItem.pathIdx, pathItem.source, id, id)}
                  value={id}
                  selected={dbViewerControl.listItemIsSelected(pathItem.pathIdx, id)}
                />
      )}
    </div>
  )
}

function ListItemSubList({ children }) {
  let items = children
  if (!Array.isArray(children)) {
    items = [children]
  }
  return (
    <div style={{display: 'flex', alignItems: 'center', gap: 5, height: 20}}>
      {items.map((item, idx) =>
        <>
          {idx !== 0 && <FiberManualRecordIcon style={{fontSize: 6, color: 'rgba(0,0,0,0.3)'}}/>}
          {item}
        </>
      )}
    </div>
  )
}

function LicenseListItem({ pathItem, id }) {
  const [fetching, setFetching] = React.useState(true)
  const [dbItem, setDbItem] = React.useState()

  React.useEffect(() => {
    async function fetchItem() {
      setFetching(true)
      const licenseItem = await getLicense(id)
      setDbItem(licenseItem)
      setFetching(false)
    }
    fetchItem()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <DBViewerListButton
      onClick={() => dbViewerControl.goToDetailItemInPath(pathItem.pathIdx, pathItem.source, id, id)}
      value={
        <div style={{display: 'flex', flexDirection: 'column', gap: 2, alignItems:'flex-start'}}>
          {fetching ?
            <CircularProgress size={20}/>:
            <ListItemSubList >
              <Typography style={{fontSize: 14}}>{dbItem?.data?.missionPackId}</Typography>
              <Typography style={{fontSize: 14}}>{`${dbItem?.data?.seats} seats`}</Typography>
              {dbItem?.data?.manuallyDeactivated ?
                <BooleanTab label={'deactivated'} value={false}/> :
                !dbItem?.data?.activated && dbItem?.data?.expires ? 'not activated':
                  dbItem?.data?.isValid ? <BooleanTab label={'valid'} value={true}/>:
                    <BooleanTab label={'expired'} value={false}/>}
            </ListItemSubList>
          }
          <Typography style={{fontSize: 12, color: 'rgba(0,0,0,0.7)'}}>{id}</Typography>
        </div>}
      selected={dbViewerControl.listItemIsSelected(pathItem.pathIdx, id)}
    />
  )
}

function BooleanTab({ label, falseLabel, value }) {
  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: value ? 'green':'red',
      color: 'white',
      borderRadius: 5,
      // padding: '5px px',
      padding: '0px 5px',
      paddingTop: 1,
      height: 16,
      marginTop: -2,
      fontWeight: 'bold',
      border: '1px solid rgba(0,0,0,0.2)',
      fontSize: 12}}
    >
      {falseLabel && !value ? falseLabel : label}
    </div>
  )
}

function GroupListItem({ pathItem, id }) {
  const [fetching, setFetching] = React.useState(true)
  const [dbItem, setDbItem] = React.useState()

  React.useEffect(() => {
    async function fetchItem() {
      setFetching(true)
      const item = await getGroup(id)
      setDbItem(item)
      setFetching(false)
    }
    fetchItem()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <DBViewerListButton
      onClick={() => dbViewerControl.goToDetailItemInPath(pathItem.pathIdx, pathItem.source, id, id)}
      value={
        <div style={{display: 'flex', flexDirection: 'column', gap: 2, alignItems:'flex-start'}}>
          {fetching ?
            <CircularProgress size={20}/>:
            <ListItemSubList >
              <Typography style={{fontSize: 14}}>{dbItem?.data?.name}</Typography>
              <Typography style={{fontSize: 14}}>{`${dbItem?.data?.missionPackId}`}</Typography>
            </ListItemSubList>
          }
          <Typography style={{fontSize: 12, color: 'rgba(0,0,0,0.7)'}}>{id}</Typography>
        </div>}
      selected={dbViewerControl.listItemIsSelected(pathItem.pathIdx, id)}
    />
  )
}

function UserListView({ pathItem, id }) {
  const [fetching, setFetching] = React.useState(true)
  const [dbItem, setDbItem] = React.useState()

  React.useEffect(() => {
    async function fetchItem() {
      setFetching(true)
      const item = await getUser(id)
      setDbItem(item)
      setFetching(false)
    }
    fetchItem()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <DBViewerListButton
      onClick={() => dbViewerControl.goToDetailItemInPath(pathItem.pathIdx, pathItem.source, id, id)}
      value={
        <div style={{display: 'flex', flexDirection: 'column', gap: 2, alignItems:'flex-start'}}>
          {fetching ?
            <CircularProgress size={20}/>:
            <ListItemSubList >
              <Typography style={{fontSize: 14}}>{dbItem?.data?.email}</Typography>
              <Typography style={{fontSize: 14}}>{`${dbItem?.data?.name ?? 'unnamed'}`}</Typography>
            </ListItemSubList>
          }
          <Typography style={{fontSize: 12, color: 'rgba(0,0,0,0.7)'}}>{id}</Typography>
        </div>}
      selected={dbViewerControl.listItemIsSelected(pathItem.pathIdx, id)}
    />
  )
}

function OrderListView({ pathItem, id }) {
  const [fetching, setFetching] = React.useState(true)
  const [dbItem, setDbItem] = React.useState()

  React.useEffect(() => {
    async function fetchItem() {
      setFetching(true)
      const item = await getOrder(id)
      setDbItem(item)
      setFetching(false)
    }
    fetchItem()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <DBViewerListButton
      onClick={() => dbViewerControl.goToDetailItemInPath(pathItem.pathIdx, pathItem.source, id, id)}
      value={
        <div style={{display: 'flex', flexDirection: 'column', gap: 2, alignItems:'flex-start'}}>
          {fetching ?
            <CircularProgress size={20}/>:
            <ListItemSubList >
              <Typography style={{fontSize: 14}}>{dbItem?.data?.purchaserEmail}</Typography>
              <Typography style={{fontSize: 14}}>{dbItem?.data?.purchased?.split('T')[0]}</Typography>
              <BooleanTab label={'claimed'} falseLabel='not claimed' value={!!dbItem?.data?.isClaimed}/>
            </ListItemSubList>
          }
          <Typography style={{fontSize: 12, color: 'rgba(0,0,0,0.7)'}}>{id}</Typography>
        </div>}
      selected={dbViewerControl.listItemIsSelected(pathItem.pathIdx, id)}
    />
  )
}

function DetailView({ pathItem }) {
  const [dbItem, setDbItem] = React.useState()

  React.useEffect(() => {
    async function fetchDetail() {
      if (pathItem.source === 'users') {
        const userItem = await getUser(pathItem.id)
        setDbItem(userItem)
      }
      if (pathItem.source === 'licenses') {
        const licenseItem = await getLicense(pathItem.id)
        setDbItem(licenseItem)
      }
      if (pathItem.source === 'orders') {
        const orderItem = await getOrder(pathItem.id)
        setDbItem(orderItem)
      }
      if (pathItem.source === 'groups') {
        const groupItem = await getGroup(pathItem.id)
        setDbItem(groupItem)
      }
    }
    fetchDetail()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathItem])

  if (!dbItem) {
    return (
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
        <CircularProgress />
      </div>
    )
  }

  if (pathItem.source === 'users') {
    return <UserDetailView pathItem={pathItem} userItem={dbItem}/>
  }
  if (pathItem.source === 'licenses') {
    return <LicenseDetailView pathItem={pathItem} licenseItem={dbItem}/>
  }
  if (pathItem.source === 'orders') {
    return <OrderDetailView pathItem={pathItem} orderItem={dbItem}/>
  }
  if (pathItem.source === 'groups') {
    return <GroupDetailView pathItem={pathItem} groupItem={dbItem}/>
  }
}

var camelCaseToWords = function(str){
  return str.match(/^[a-z]+|[A-Z][a-z]*/g).map(function(x){
    return x[0].toUpperCase() + x.substr(1).toLowerCase()
  }).join(' ')
}

function DetailFieldPanel({fields, data}) {
  return (
    <div style={{display: 'flex', flexDirection: 'column', gap: 10, width: '100%', padding: '10px 10px'}}>
      {fields.map((field) => {
        return (
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center'}}>
            <Typography style={{fontSize:14, color: 'rgba(0,0,0,0.6)'}}>{
              camelCaseToWords(field)
            }</Typography>
            <Typography style={{fontSize:14}}>{`${data[field]}`}</Typography>
          </div>
        )
      })}
    </div>
  )
}

function DetailListButtonLabel({ label, value }) {
  return (
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 5}}>
      <Typography style={{fontSize: 14}}>{label}</Typography>
      <Typography style={{fontSize: 13, color: 'rgba(0,0,0,0.6)'}}>{`(${value?.length})`}</Typography>
    </div>
  )
}

function UserDetailView({ userItem, pathItem }) {
  const fields = [
    'email',
    'name',
    'lastSignIn',
    'lastActivity',
    'lastActiveGroup',
    'activeLicense',
    'rootFolder',
    'unclaimed',
    'id',
    'authId',
    'lastIpAddress',
    'ltiConsumerKey',
  ]

  return (
    <div style={{height: '100%'}}>
      <DBViewerListButton
        onClick={() => dbViewerControl.goToListItemInPath(pathItem.pathIdx, 'licenses', userItem?.data?.licenses, 'licenses')}
        value={<DetailListButtonLabel label={'Licenses'} value={userItem?.data?.licenses} />}
        selected={dbViewerControl.listItemIsSelected(pathItem.pathIdx, 'licenses')}
      />
      <DBViewerListButton
        onClick={() => dbViewerControl.goToListItemInPath(pathItem.pathIdx, 'groups', userItem?.data?.ownedGroups, 'ownedGroups', 'Owned Groups')}
        value={<DetailListButtonLabel label={'Owned Groups'} value={userItem?.data?.ownedGroups} />}
        selected={dbViewerControl.listItemIsSelected(pathItem.pathIdx, 'ownedGroups')}
      />
      <DBViewerListButton
        onClick={() => dbViewerControl.goToListItemInPath(pathItem.pathIdx, 'groups', userItem?.data?.memberGroups, 'memberGroups', 'Member Groups')}
        value={<DetailListButtonLabel label={'Member Groups'} value={userItem?.data?.memberGroups} />}
        selected={dbViewerControl.listItemIsSelected(pathItem.pathIdx, 'memberGroups')}
      />
      <DetailFieldPanel {...{fields, data:userItem.data}} />
    </div>
  )
}

function LicenseDetailView({ pathItem, licenseItem }) {
  const fields = [
    'missionPackId',
    'seats',
    'type',
    'isValid',
    'created',
    'activated',
    'expires',
    'expiration',
    'isFull',
    'manuallyDeactivated',
    'id',
  ]

  return (
    <div>
      <DBViewerListButton
        onClick={() => dbViewerControl.goToListItemInPath(pathItem.pathIdx, 'orders', [licenseItem?.data?.orderId], 'orders', 'Parent Order')}
        value={<DetailListButtonLabel label={'Parent Order'} value={[licenseItem?.data?.orderId]} />}
        selected={dbViewerControl.listItemIsSelected(pathItem.pathIdx, 'orders')}
      />
      <DBViewerListButton
        onClick={() => dbViewerControl.goToListItemInPath(pathItem.pathIdx, 'users', licenseItem?.data?.admins, 'admins', 'License Admins')}
        value={<DetailListButtonLabel label={'License Admins'} value={licenseItem?.data?.admins} />}
        selected={dbViewerControl.listItemIsSelected(pathItem.pathIdx, 'admins')}
      />
      <DetailFieldPanel {...{fields, data:licenseItem.data}} />
    </div>
  )
}

function isUUID ( uuid ) {
  let s = '' + uuid

  s = s.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$')
  if (s === null) {
    return false
  }
  return true
}

function OrderDetailView({pathItem, orderItem}) {
  const fields = [
    'claimCode',
    'purchaserEmail',
    'purchaserName',
    'purchaseSource',
    'purchased',
    'isClaimed',
  ]

  let claimedBy = []
  let claimedByValid = false
  if (orderItem?.data?.claimedBy) {
    claimedBy = [orderItem?.data?.claimedBy]
    claimedByValid = isUUID(orderItem?.data?.claimedBy)
  }

  if (!claimedByValid) {
    orderItem.data.authId = orderItem?.data?.claimedBy
    fields.push('authId')
  }
  return (
    <div>
      <DBViewerListButton
        onClick={() => dbViewerControl.goToListItemInPath(pathItem.pathIdx, 'licenses', orderItem?.data?.licenseIds, 'licenses', 'Child Licenses')}
        value={<DetailListButtonLabel label={'Child Licenses'} value={orderItem?.data?.licenseIds} />}
        selected={dbViewerControl.listItemIsSelected(pathItem.pathIdx, 'licenses')}
      />
      <DBViewerListButton
        onClick={() => dbViewerControl.goToListItemInPath(pathItem.pathIdx, 'users', claimedBy, 'owner', 'Claimed By')}
        disabled={!claimedByValid}
        value={<DetailListButtonLabel label={'Claimed By'} value={claimedBy} />}
        selected={dbViewerControl.listItemIsSelected(pathItem.pathIdx, 'owner')}
      />
      <DetailFieldPanel {...{fields, data: orderItem.data}} />
    </div>
  )
}

function GroupDetailView({pathItem, groupItem}) {
  const fields = [
    'name',
    'missionPackId',
    'licenseType',
    'created',
    'lastModified',
    'lastModifiedBy',
    'joinActive',
    'joinCode',
    'id',
    'lmsId',
    'lessonUnlockCode',
    'lessonLockCode',
    'contestId',
  ]

  return (
    <div>
      <DBViewerListButton
        onClick={() => dbViewerControl.goToListItemInPath(pathItem.pathIdx, 'users', [groupItem?.data?.ownerId], 'users', 'Group Owner')}
        value={<DetailListButtonLabel label={'Group Owner'} value={[groupItem?.data?.ownerId]} />}
        selected={dbViewerControl.listItemIsSelected(pathItem.pathIdx, 'users')}
      />
      <DetailFieldPanel {...{fields, data: groupItem.data}} />
    </div>
  )
}

function EmptyView() {
  return (
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
      {'No data to display'}
    </div>
  )
}

function QueryTextField({ label, labelKey }) {
  const { queryState } = useDBViewer()
  function keyPress(ev){
    if (ev.key === 'Enter') {
      dbViewerControl.handleRunQuery(queryState)
      ev.preventDefault()
    }
  }

  return (
    <OutlinedTextField
      onKeyDown={keyPress}
      label={label}
      style={{width: '100%'}}
      value={queryState[labelKey]}
      onChange={event => dbViewerControl.handleQueryTextChange(labelKey, event.target.value)}
    />
  )
}

function QueryPanel() {
  const { queryState } = useDBViewer()

  return (
    <div style={{display: 'flex', alignItems: 'space-between', flexDirection: 'column', height: '100%', width: '100%'}}>
      <div style={{height: '100%', display: 'flex', flexDirection: 'column', alignItems:'flex-start', gap: 10, width: '100%'}}>
        <Typography variant='h5'>Query By</Typography>
        <div style={{height: 5}}/>
        <Typography variant='h6'>User</Typography>
        <QueryTextField label='User Id' labelKey='userId'/>
        <QueryTextField label='User Email' labelKey='userEmail'/>
        <QueryTextField label='User Name' labelKey='userName'/>
        <QueryTextField label='Auth Id' labelKey='authId'/>
        <div style={{height: 5}}/>
        <Typography variant='h6'>Order</Typography>
        <QueryTextField label='Order Id' labelKey='orderId'/>
        <QueryTextField label='Claim Code' labelKey='claimCode'/>
        <QueryTextField label='Purchaser Email' labelKey='purchaserEmail'/>
      </div>
      <div style={{display: 'flex', alignItems: 'flex-end', justifyContent: 'center'}}>
        <Button
          onClick={() => dbViewerControl.handleRunQuery(queryState)}
          variant='contained'
        >
          {'Run Query'}
        </Button>
      </div>
    </div>
  )
}

function formatDBPathTitle(pathItem) {
  if (!pathItem) {
    return '-'
  }

  if (pathItem.type === PATH_TYPES.LIST) {
    return String(pathItem.source).charAt(0).toUpperCase() + String(pathItem.source).slice(1)
  }

  return pathItem.id
}

function DBViewPanel({ panelIdx, width }) {
  let idx = panelIdx
  const { dbPath } = useDBViewer()
  const [pathItem, setPathItem] = React.useState()

  React.useEffect(() => {
    let item
    let pathIdx
    if (dbPath.length > 2) {
      pathIdx = dbPath.length - 3 + idx
    } else if (dbPath.length > idx) {
      pathIdx = idx
    }

    if (pathIdx !== undefined) {
      item = dbPath[pathIdx]
      item.pathIdx = pathIdx
      setPathItem(JSON.parse(JSON.stringify(item)))
    } else {
      setPathItem()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dbPath])

  return (
    <div style={{width}}>
      <div style={{backgroundColor: 'rgba(0,0,0,0.02)', padding: '5px 5px', display: 'flex', alignItems:'center', justifyContent: 'flex-start', height: 50}}>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingLeft: 5}}>
          {!pathItem ? null:
            pathItem.type === PATH_TYPES.LIST ?
              <ListIcon style={{fontSize: pathItem.type !== PATH_TYPES.LIST ? 18:null}} />:<DescriptionIcon style={{fontSize: 18}}  />
          }
          {pathItem && pathItem.type !== PATH_TYPES.LIST &&
          <Typography style={{fontSize: 14, color: 'rgba(0,0,0,0.6)'}}>
            {`(${pathItem?.source.slice(0, pathItem?.source.length - 1)})`}
          </Typography>
          }
        </div>
        <div style={{width: 10}}/>
        {formatDBPathTitle(pathItem)}
        {pathItem?.listSubtitle && <div style={{color: 'rgba(0,0,0,0.6)', paddingLeft: 5, paddingTop: 2, fontSize: 13}}>{`(${pathItem?.listSubtitle})`}</div>}
      </div>
      <Divider style={{backgroundColor:  borderColor}}/>
      <div style={{height: 'calc(100% - 50px)', overflowY: 'auto', overflowX: 'auto'}}>
        {!pathItem ? <EmptyView />:
          pathItem.type === PATH_TYPES.LIST ?
            <ListView pathItem={pathItem}/>:
            <DetailView pathItem={pathItem}/>
        }
      </div>
    </div>
  )
}

function DBViewerBreadCrumbs() {
  const { dbPath } = useDBViewer()
  const lastItem = dbPath[dbPath.length - 1]
  const linkCrumbs = dbPath.slice(0, dbPath.length - 1)

  return (
    <div style={{display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'flex-start', height: '100%', paddingLeft: 4}}>
      <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />} aria-label='breadcrumb' itemsAfterCollapse={4} itemsBeforeCollapse={3}  maxItems={7}>
        <IconButton
          onClick={dbViewerControl.handleHomeClick}
          disabled={dbPath.length === 0}
        >
          <HomeIcon />
        </IconButton>
        {linkCrumbs.map((pathItem, idx) =>
          <Link color='inherit' onClick={() => dbViewerControl.goToPathIndex(idx)} style={{cursor:'pointer'}}>
            {formatDBPathTitle(pathItem)}
          </Link>
        )}
        {dbPath.length !== 0 && <Typography color='textPrimary' style={{userSelect: 'none'}}>{formatDBPathTitle(lastItem)}</Typography>}
      </Breadcrumbs>
    </div>
  )
}

function DBViewerPage() {
  return (
    <DBViewerProvider>
      <div style={{height: '100%', display: 'flex', gap: 10, width: '100%'}}>
        <Card variant='outlined' style={{height: '100%', padding: '20px 20px', display: 'flex', flexDirection: 'column', alignItems:'flex-start', gap: 10, width: 300, borderColor}}>
          <QueryPanel />
        </Card>
        <Card variant='outlined' style={{height: '100%', width: '100%', borderColor}}>
          <div style={{height: 50, width: '100%'}}>
            <DBViewerBreadCrumbs />
          </div>
          <Divider style={{backgroundColor:  borderColor}}/>
          <div style={{display: 'flex', width: '100%', height: 'calc(100% - 50px)'}}>
            <DBViewPanel panelIdx={0} width='100%'/>
            <Divider style={{backgroundColor:  borderColor}} orientation='vertical'/>
            <DBViewPanel panelIdx={1} width='100%'/>
            <Divider style={{backgroundColor:  borderColor}} orientation='vertical'/>
            <DBViewPanel panelIdx={2} width='100%'/>
          </div>
        </Card>
      </div>
    </DBViewerProvider>
  )
}

export { DBViewerPage }
import React from 'react'
import { Button } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import LoginPanel from './common/LoginPanel'
import orientationImg from './common/orientation.svg'

const useStyles = makeStyles(theme => ({
  appHeader: {
    minHeight: 60,
    backgroundColor: theme.palette.custom.secondary,
    display:'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position:'absolute',
    width: '100%',
  },
  headerImage: {
    alignSelf: 'center',
    width:40,
    height:40,
    marginRight: 15,
  },
}))

function HeaderTab({ label, value, tabSelected, onClick }) {
  return (
    <Button
      variant='outlined'
      style={{
        color: tabSelected === value ? 'black':'white',
        textTransform: 'none',
        border: '1px solid white',
        marginLeft: 3,
        marginRight: 3,
        borderRadius: 5,
        backgroundColor: tabSelected === value ? 'white' : 'transparent',
      }}
      onClick={() => onClick(value)}
    >
      {label}
    </Button>
  )
}

function AppHeader({ openPage, setOpenPage, userAuthenticated }) {
  const classes = useStyles()

  if (!userAuthenticated) {
    return (
      <Paper square elevation={5} className={classes.appHeader}>
        <div></div>
        <div style={{display: 'flex', justifyContent: 'flex-end', paddingRight: '15px', width: '30%'}}>
          <LoginPanel />
        </div>
      </Paper>
    )
  }

  return (
    <Paper square elevation={0} className={classes.appHeader}>
      <div style={{display:'flex', alignItems:'center', paddingLeft: '15px', gap: 10, width: '30%'}}>
        <img className={classes.headerImage} alt={''} src={orientationImg}/>
        <Typography variant='h6' style={{color: 'white'}}>
          {'License Portal'}
        </Typography>
      </div>
      <div
      >
        <HeaderTab label='Order Builder' value={'builder'} tabSelected={openPage} onClick={setOpenPage}/>
        {/* <HeaderTab label='Reseller Controller' value='reseller' tabSelected={openPage} onClick={setOpenPage}/> */}
        <HeaderTab label='DB Viewer' value='dbviewer' tabSelected={openPage} onClick={setOpenPage}/>
      </div>
      <div style={{display: 'flex', justifyContent: 'flex-end', paddingRight: '15px', width: '30%'}}>
        <LoginPanel />
      </div>
    </Paper>
  )
}

export { AppHeader }